body,
#root {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
}

input.ltr-input,
.ltr-input input {
  text-align: left;
  direction: ltr;
}

input.rtl-placeholder::placeholder,
.rtl-placeholder input::placeholder {
  text-align: right;
  direction: rtl;
}

@media (max-width: 1000px) {
  .MuiTableCell-body.MuiTableCell-root {
    padding: 5px;
  }
}

.MuiAlert-message {
  width: 100%;
}

.MuiDialog-paperScrollBody.MuiDialog-paper {
  overflow-y: hidden;
}

.h_iframe-aparat_embed_frame {
  position: relative;
  margin: 0 auto 30px;
}

.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
